import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import "../../../css/custom-css/custom-products-services.css"

import Layout from "../../../components/custom-components/Layout"
import BannerHeader from "../../../components/custom-components/BannerHeader"

import bg from "../../../images/products-and-services/header-cmo.webp"
import Seo from "../../../components/seo"

export const query = graphql`
  {
    image1: contentfulServices(title: { eq: "cmo-1" }) {
      image {
        gatsbyImageData
      }
    }
    image2: contentfulServices(title: { eq: "cmo-2" }) {
      image {
        gatsbyImageData
      }
    }
    image3: contentfulServices(title: { eq: "cmo-3" }) {
      image {
        gatsbyImageData
      }
    }
  }
`

const CMO = () => {
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : ""
  const data = useStaticQuery(query)
  const image1 = data.image1
  const image2 = data.image2
  const image3 = data.image3
  return (
    <Layout page="productsPage">
      <Seo title={intl.formatMessage({ id: "header.cmo" })} />

      <BannerHeader
        background={bg}
        title={intl.formatMessage({ id: "header.cmo" })}
        servicesDetails
      />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div>
              <Link href={`${locale}/products-and-services`}>
                <div className="icon-content">
                  <h5 className="dlab-title">
                    <span className="icon-sm">
                      <i className="ti-arrow-left"></i>
                    </span>
                    {intl.formatMessage({ id: "backbtn" })}
                  </h5>
                </div>
              </Link>
            </div>

            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li className="active">
                      <Link to={`${locale}/products-and-services/services/cmo`}>
                        {intl.formatMessage({ id: "header.cmo" })}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${locale}/products-and-services/services/toll-in-testing`}
                      >
                        {intl.formatMessage({ id: "header.tollintesting" })}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${locale}/products-and-services/services/process-analytical-development`}
                      >
                        {intl.formatMessage({
                          id: "header.processandanalyticaldevelopment",
                        })}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                <div className="row">
                  <div className="col-lg-6 col-md-12 m-b30">
                    <div className="dlab-box">
                      <div className="dlab-media">
                        <GatsbyImage image={image1.image.gatsbyImageData} alt="" />
                      </div>
                      <div className="dlab-info m-t30 ">
                        <h4 className="dlab-title m-t0">
                          {intl.formatMessage({
                            id: "cmo.paragraph.1.title",
                          })}
                        </h4>
                        <p className="text-justify">
                          {intl.formatMessage({
                            id: "cmo.paragraph.1.desc",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dlab-box">
                      <div className="dlab-media m-b30 p-b5">
                        <GatsbyImage image={image2.image.gatsbyImageData} alt="" />
                      </div>
                      <div className="dlab-media">
                        <GatsbyImage image={image3.image.gatsbyImageData} alt="" />
                      </div>
                      <div className="dlab-info m-t30">
                        <h4 className="dlab-title m-t0">
                          {intl.formatMessage({
                            id: "cmo.paragraph.2.title",
                          })}
                        </h4>
                        <p className="text-justify">
                          {intl.formatMessage({
                            id: "cmo.paragraph.2.desc",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CMO
